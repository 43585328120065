import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';

import {Observable} from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import { Router } from '@angular/router';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

  constructor(private router : Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).do((event: HttpEvent<any>) => {
    }, (err: any) => {
      // if (err instanceof HttpErrorResponse) {
      //   if (err.error && err.error.error && err.error.error.statusCode === 401) {
      //     localStorage.removeItem('currentUser');
      //     this.router.navigate(['/login']);
      //   }
      // }
    });
  }
}
