import { TagsServices } from './../../services/api/tagsService';
import {Component, OnInit} from '@angular/core';
import { ProjectServices } from '../../services/api/projectServices';


@Component({
  selector: 'app-home',
  templateUrl: './allProjects.component.html',
  styleUrls: ['./allProjects.component.scss']
})

export class AllProjectsComponent implements OnInit {

  projectsData: any;
  tags: any = [];
  allSelected: boolean = true;
  selectedForModel='';
  slideConfig2 = {"slidesToShow": 3, "slidesToScroll": 1};


  constructor(
    private projectService: ProjectServices,
    private tagsService: TagsServices) {}

  async ngOnInit() {
    this.projectsData = await this.projectService.list().toPromise();
    this.tags = await this.tagsService.list().toPromise();
  }
  openImage(slide, item){
    item.selectedImage = slide;
  }
  openModel(selected){
    this.selectedForModel = selected
  }
  async toggleTabs(data: any) {
    if (data === 'all') {
      this.tags.forEach(tag => {
          tag.active = false;
      });
      this.allSelected = true;
      this.projectsData = await this.projectService.list().toPromise();
    } else if (!!data && data.id) {
      this.allSelected = false;
      this.tags.forEach(tag => {
        if (tag.id === data.id) {
          tag.active = true;
        } else {
          tag.active = false;
        }
      });
      this.projectsData = await this.projectService.query({where: {tags: {like: data.name}}}).toPromise();
    }
  }
}
