import { Injectable } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../baseAPI';
import { Candidate } from 'src/app/shared/models/candidate';


@Injectable()
export class CandidateServices extends BaseApiService<Candidate> {

    constructor(http: HttpClient) {
        super(http);
        this.url = '/candidates';
    }
}


