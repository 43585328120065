import { Injectable } from "@angular/core";


import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "../baseAPI";
import { Vacancy } from '../../shared/models/vacancy';


@Injectable()
export class VacanciesServices extends BaseApiService<Vacancy> {

    constructor(http: HttpClient) {
        super(http);
        this.url = '/vacancies';
    }
}


