import {Component, OnInit} from '@angular/core';
import { VacanciesServices } from 'src/app/services/api/vacanciesServices';
import { Router } from '@angular/router';


@Component({
  selector: 'app-home',
  templateUrl: './allVacancies.component.html',
  styleUrls: ['./allVacancies.component.scss']
})

export class AllVacanciesComponent implements OnInit {

  vacanciesData: any;

  constructor(
    private vacanciesService: VacanciesServices,
    private router: Router) {}

  async ngOnInit() {
    this.vacanciesService.query({order: 'modified DESC'}).subscribe(res => {
      this.vacanciesData = res;
    });
  }

  applyForVacancy(vacancyId) {
    this.router.navigate([`/applyCadidate/${vacancyId}`]);
  }
}
