import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { I18nService } from './services/i18n.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './sharedModule';
import { RequestInterceptor } from './services/auth/_interceptors/httpInterceptor';
import { HomeComponent } from './pages/home/home.component';
import { RequestServiceComponent } from './pages/requestService/requestService.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RequestServices } from './services/api/requestServices';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { ProjectServices } from './services/api/projectServices';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { AllProjectsComponent } from './pages/allProjects/allProjects.component';
import { VacanciesServices } from './services/api/vacanciesServices';
import { AllVacanciesComponent } from './pages/allVacancies/allVacancies.component';
import { contactsService } from './services/api/contactService';
import { TagsServices } from './services/api/tagsService';
import {CdkStepperModule} from '@angular/cdk/stepper';
import {
  MatStepperModule, MatInputModule,
  MatButtonModule, MatAutocompleteModule,
  MatDatepickerModule, MatNativeDateModule, MatRadioModule, MatProgressSpinnerModule } from '@angular/material';
import { CandidateServices } from './services/api/candidateServices';
import { ApplyCandidateComponent } from './pages/applyCandidate/applyCandidate.component';

@NgModule({
  declarations: [
    AppComponent,
    RequestServiceComponent,
    AllProjectsComponent,
    AllVacanciesComponent,
    ApplyCandidateComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SlickCarouselModule,
    CdkStepperModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(),
    MatInputModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatStepperModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    SharedModule
  ],
  providers: [
    RequestServices,
    ProjectServices,
    VacanciesServices,
    contactsService,
    TagsServices,
    CandidateServices,
    I18nService,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptor,
        multi: true,
      }],
  bootstrap: [AppComponent]
})
export class AppModule { }
