import { Injectable } from "@angular/core";


import { HttpClient } from "@angular/common/http";
import { BaseApiService } from "../baseAPI";
import { Project } from '../../shared/models/project';


@Injectable()
export class ProjectServices extends BaseApiService<Project> {

    constructor(http: HttpClient) {
        super(http);
        this.url = '/projects';
    }
}


