import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { contactsService } from '../../services/api/contactService';
import { Contact } from '../../shared/models/contact';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})

export class FooterComponent implements OnInit {

  data: Contact;

  constructor(private api: contactsService,
    private route: ActivatedRoute) {
}

 async ngOnInit() {
  this.data = await this.api.list().toPromise();
 }
}

