import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectServices } from '../../services/api/projectServices';
import { Project } from '../../shared/models/project';
import { VacanciesServices } from 'src/app/services/api/vacanciesServices';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {
  projectsData: any;
  vacanciesData: any;
  selectedForModel = '';
  slideConfig2 = {
    "nextArrow":"<div class='nav-btn next-slide'></div>",
    "prevArrow":"<div class='nav-btn prev-slide'></div>",
    "slidesToShow": 3,
    "slidesToScroll": 1
  };

  constructor(private route: ActivatedRoute,
    private projectService: ProjectServices,
    private vacanciesService: VacanciesServices,
    private router: Router,
  ) {
  }

  slideConfig = {
    "slidesToShow": 1,
    "slidesToScroll": 1,
    "dots": false,
    "infinite": true,
    nextArrow: '<i class="fa fa-arrow-right" style="position: absolute;z-index: 1000;top: 50%;left: -30px;background-color: #eee;font-size: 12px;border-radius: 50px; padding: 5px;cursor:pointer"></i>',
    prevArrow: '<i class="fa fa-arrow-left" style="position: absolute;z-index: 1000;top: 50%;right: -30px;background-color: #eee;font-size: 12px;border-radius: 50px; padding: 5px;cursor:pointer"></i>'

  };
  async ngOnInit() {
    this.projectsData = await this.projectService.query({ where: { putAtHome: { neq: false } } }).toPromise()
    this.vacanciesService.query({ order: 'modified DESC', limit: 3 }).subscribe(res => {
      this.vacanciesData = res;
    });
  }
  openImage(slide, item) {
    item.selectedImage = slide;
  }
  openModel(selected) {
    this.selectedForModel = selected
  }
  goToLinkedIn() {
    window.open('https://www.linkedin.com/company/thinkloudllc', '_blank');
  }

  applyForVacancy(vacancyId) {
    this.router.navigate([`/applyCadidate/${vacancyId}`]);
  }
}
