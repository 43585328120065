import { CandidateServices } from './../../services/api/candidateServices';
import { Candidate } from './../../shared/models/candidate';
import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';
import { MatDatepickerInputEvent } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import 'rxjs/add/observable/forkJoin';


@Component({
  selector: 'app-candidate',
  templateUrl: './applyCandidate.component.html',
  styleUrls: ['./applyCandidate.component.scss'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
  }]
})

export class ApplyCandidateComponent implements OnInit {
  data: Candidate = {};
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;
  fivethFormGroup: FormGroup;
  firstFormSubmitted = false;
  fourthFormSubmitted = false;
  isFileUploading = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private candidateService: CandidateServices,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    ) {}

  ngOnInit() {
    const vacancyId = this.route.snapshot.params.vacancyId;
    this.data.vacancyId = vacancyId ? vacancyId : '';
    this.firstFormGroup = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      dateOfBirth: ['', Validators.required],
      gender: ['', Validators.required],
      city: [''],
      area: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      mobile: ['', [Validators.required, Validators.pattern('(01)[0-9]{9}')]],
      email: ['', [Validators.required, Validators.email]],
      linkedIn: [''],
    });
    this.thirdFormGroup = this.formBuilder.group({
      yearsOfExperience: ['', [Validators.pattern(/^(?:\d*\.)?\d+$/)]],
      company1: [''],
      company2: [''],
      company3: [''],
    });
    this.fourthFormGroup = this.formBuilder.group({
      currentSalary: ['', [Validators.required, Validators.pattern(/^(?:\d*\.)?\d+$/)]],
      exceptedSalary: ['', [Validators.required, Validators.min(2000), Validators.pattern(/^(?:\d*\.)?\d+$/)]],
      visitOffice: ['', Validators.required]
    });
    this.fivethFormGroup = this.formBuilder.group({
      faculty: ['', Validators.required],
      graduationDegree: [''],
      graduationProject: [''],
      graduationYear: ['', [Validators.required, Validators.pattern(/^\d+$/)]],
    });
  }

  get firstForm() { return this.firstFormGroup.controls; }
  get fourthForm() { return this.fourthFormGroup.controls; }
  get fivethForm() { return this.fivethFormGroup.controls; }
  firstFormSubmit() {
    this.firstFormSubmitted = true;
  }
  fourthFormSubmit() {
    this.fourthFormSubmitted = true;
  }

  addEvent(type: string, event: MatDatepickerInputEvent<Date>) {
    if (!!event.value) {
      this.data.dateOfBirth = event.value;
    }
  }

  onFileChange(event) {
    const fileList: FileList = event.target.files;
    const obs: any[] = [];
    if (fileList.length > 0) {
            for (let i = 0; i < fileList.length; i++) {
                const file: File = fileList[i];
                const ob = this.candidateService.upload(file);
                obs.push(ob);
            }
            this.isFileUploading = true;
            return Observable.forkJoin(obs)
                .subscribe(files => {
                        this.data.cv =  files[0]['path'];
                        this.isFileUploading = false;
                });
        }
  }

  submit() {
    if (this.firstFormGroup.invalid || this.secondFormGroup.invalid || this.thirdFormGroup.invalid ||
        this.fourthFormGroup.invalid || this.fivethFormGroup.invalid || !this.data) {
          return;
    }
    try {
      this.candidateService.save(this.data).subscribe(res => {
        if (!!res) {
          this.toastr.success('Your Applying Data sent successfully !', 'Success', {timeOut: 10000, positionClass: 'toast-top-right'});
          this.router.navigate(['allVacancies']);
        }
      }, err => {
        this.toastr.error('Something went wrong, please try again !', 'Erorr', {timeOut: 10000, positionClass: 'toast-top-right'});
      });
    } catch (err) {
      this.toastr.error('Something went wrong, please try again !', 'Erorr', {timeOut: 10000, positionClass: 'toast-top-right'});
    }
  }
}
