import {Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { RequestServices } from '../../services/api/requestServices';
import { ToastrService } from 'ngx-toastr';
import { RequestService } from '../../shared/models/requestService';


@Component({
  selector: 'app-home',
  templateUrl: './requestService.component.html',
  styleUrls: ['./requestService.component.scss']
})

export class RequestServiceComponent implements OnInit {

  data: any;
  services: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private requestService: RequestServices,
    private toastr: ToastrService,
    ) {}

 async ngOnInit() {
  this.data = this.route.snapshot.data as RequestService;
 }
 servicesChanged(event: any) {
  this.services = event.target.value;
  this.data.selectedService = event.target.value;
}
 async onSubmit(mForm: any) {
  if (mForm.valid) {

    await this.requestService.save(this.data).subscribe((r)=>{
      r = this.data;
      this.toastr.success('data sent successfully', 'Success', {timeOut: 10000,positionClass:'toast-bottom-center'});
    })


}
 }
}
