import { Injectable } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { BaseApiService } from '../baseAPI';
import { Tag } from 'src/app/shared/models/tag';

@Injectable()
export class TagsServices extends BaseApiService<Tag> {

    constructor(http: HttpClient) {
        super(http);
        this.url = '/tags';
    }
}


