import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { RequestServiceComponent } from './pages/requestService/requestService.component';
import { AllProjectsComponent } from './pages/allProjects/allProjects.component';
import { AllVacanciesComponent } from './pages/allVacancies/allVacancies.component';
import { ApplyCandidateComponent } from './pages/applyCandidate/applyCandidate.component';


const routes: Routes = [
  { path: '', component: HomeComponent},
  { path: 'requestService', component: RequestServiceComponent},
  { path: 'allProjects', component: AllProjectsComponent},
  { path: 'allVacancies', component: AllVacanciesComponent},
  { path: 'applyCadidate/:vacancyId', component: ApplyCandidateComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes),
           RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
